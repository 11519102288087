.wrapper {
  position: relative;
  z-index: 0;
  background: #002882;
  padding: calc(26px * var(--sizeCoefficient)) calc(30px * var(--sizeCoefficient));
  box-sizing: border-box;
  overflow: hidden;
}

.backdropWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.backdrop {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 120%;
  border-radius: 50%;
  background: rgba(0, 200, 255, 0.4);
  filter: blur(152.679px);
}
