.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: calc(-10px * var(--sizeCoefficient)) calc(-15px * var(--sizeCoefficient));
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: calc(10px * var(--sizeCoefficient)) calc(15px * var(--sizeCoefficient));
  width: calc(100% / var(--columns) - 30px * var(--sizeCoefficient));
  padding: calc(20px * var(--sizeCoefficient)) calc(40px * var(--sizeCoefficient));
  background: #002882;
  border-radius: calc(20px * var(--sizeCoefficient));
  font-weight: 600;
  font-size: calc(23px * var(--sizeCoefficient));
  line-height: calc(29px * var(--sizeCoefficient));
  color: #FFFFFF;
  text-align: center;
  box-sizing: border-box;

  &.large {
    padding: calc(40px * var(--sizeCoefficient)) calc(80px * var(--sizeCoefficient));
    font-weight: 700;
    font-size: calc(32px * var(--sizeCoefficient));
    line-height: calc(46px * var(--sizeCoefficient));
  }
}
