.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: calc(240px * var(--sizeCoefficient));
}

.randomizers {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.randomizer {
  & + & {
    margin-top: calc(50px * var(--sizeCoefficient));
  }
}

.completeButton {
  margin-top: calc(144px * var(--sizeCoefficient));
}
