.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: calc(38px * var(--sizeCoefficient)) calc(50px * var(--sizeCoefficient)) calc(50px * var(--sizeCoefficient));
  background: #002882;
  border-radius: calc(30px * var(--sizeCoefficient));
  width: calc(1000px * var(--sizeCoefficient));
}

.logo {
  max-width: 100%;
  max-height: calc(var(--logo-max-height) * var(--sizeCoefficient));
}

.title {
  margin-top: calc(10px * var(--sizeCoefficient));
  font-weight: 700;
  font-size: calc(50px * var(--sizeCoefficient));
  line-height: calc(64px * var(--sizeCoefficient));
  text-align: center;
  color: #ffffff;
}

.text {
  font-weight: 600;
  font-size: calc(50px * var(--sizeCoefficient));
  line-height: calc(64px * var(--sizeCoefficient));
  text-align: center;
  color: #ffffff;
}
