.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: calc(31px * var(--sizeCoefficient)) calc(27px * var(--sizeCoefficient)) calc(13px * var(--sizeCoefficient))
    calc(14px * var(--sizeCoefficient));
  background: #002882;
  border-radius: calc(20px * var(--sizeCoefficient));

  &:not(.active) {
    opacity: 0.5;
    filter: blur(5px);
    cursor: pointer;
  }

  &.gameCompleted {
    cursor: default;

    .stepsIncrementButton,
    .skillsIncrementButton {
      cursor: default;
      opacity: 0;
      pointer-events: none;
    }
  }
}

.steps,
.skills {
  display: flex;
  align-items: center;
}

.skills {
  margin-top: calc(30px * var(--sizeCoefficient));
}

.stepsTitle,
.skillsTitle {
  font-weight: 600;
  font-size: calc(13px * var(--sizeCoefficient));
  line-height: calc(11px * var(--sizeCoefficient));
  color: #ffffff;
}

.stepsInput,
.skillsInput {
  flex-shrink: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: calc(12px * var(--sizeCoefficient));
  width: calc(42px * var(--sizeCoefficient));
  height: calc(42px * var(--sizeCoefficient));
  background: #ffffff;
  border-radius: calc(8px * var(--sizeCoefficient));
}

.stepsValue,
.skillsValue {
  font-weight: 600;
  font-size: calc(25px * var(--sizeCoefficient));
  line-height: calc(11px * var(--sizeCoefficient));
  color: #002882;
  text-align: center;
}

.stepsIncrementButton,
.skillsIncrementButton {
  position: absolute;
  width: calc(34px * var(--sizeCoefficient));
  height: calc(34px * var(--sizeCoefficient));
  top: calc(8px * var(--sizeCoefficient));
  right: calc(8px * var(--sizeCoefficient));
  transform: translate(100%, -100%);
  cursor: pointer;
}
