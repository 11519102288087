.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: calc(16px * var(--sizeCoefficient)) calc(14px * var(--sizeCoefficient));
  background: #002882;
  border-radius: calc(20px * var(--sizeCoefficient));

  &:not(.active) {
    opacity: 0.5;
    filter: blur(5px);
  }

  &:not(.active):not(.gameCompleted) {
    cursor: pointer;
  }

  &.active {
    .name {
      cursor: pointer;
    }

    &.superpowerAvailable:not(.gameCompleted) {
      .superpower {
        cursor: pointer;
      }
    }

    &:not(.superpowerAvailable) {
      .superpower {
        opacity: 0.5;
        filter: blur(2px);
      }
    }
  }
}

.name {
  padding: calc(8px * var(--sizeCoefficient)) calc(15px * var(--sizeCoefficient));
  background: #00abff;
  border-radius: calc(20px * var(--sizeCoefficient));
  color: #ffffff;
  text-align: center;
  font-weight: 600;
  font-size: calc(14px * var(--sizeCoefficient));
  line-height: calc(14px * var(--sizeCoefficient));
}

.main {
  display: flex;
  margin-top: calc(14px * var(--sizeCoefficient));
}

.avatar {
  flex-shrink: 0;
  width: calc(80px * var(--sizeCoefficient));
  height: calc(80px * var(--sizeCoefficient));
}

.superpower {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-left: calc(14px * var(--sizeCoefficient));
}

.superpowerTitle {
  font-weight: 700;
  font-size: calc(14px * var(--sizeCoefficient));
  line-height: calc(18px * var(--sizeCoefficient));
  color: #ffffff;
}

.superpowerValue {
  margin-top: calc(4px * var(--sizeCoefficient));
  font-weight: 400;
  font-size: calc(13px * var(--sizeCoefficient));
  line-height: calc(15px * var(--sizeCoefficient));
  color: #ffffff;
}
