.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 5% 7%;
  border-top-right-radius: 5% 7%;
  border-bottom-right-radius: 5% 7%;
  border-bottom-left-radius: 5% 7%;
  cursor: pointer;
  padding: 5% 10% 7%;
  box-sizing: border-box;
  z-index: 0;
  overflow: hidden;
  background-color: #002882;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left top;

  &.emptyCards {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }

  &.gameCompleted {
    opacity: 0;
    pointer-events: none;
    cursor: default;
  }
}

.backgroundOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #002982;
  opacity: 0.8;
  z-index: -1;
}

.logo {
  width: 100%;
  max-height: 100%;
}
