@font-face {
  font-family: 'VTB Group';
  src: url('./assets/fonts/VTB45.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'VTB Group';
  src: url('./assets/fonts/VTB55.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'VTB Group';
  src: url('./assets/fonts/VTB65.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'VTB Group';
  src: url('./assets/fonts/VTB75.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'VTB Group';
  src: url('./assets/fonts/VTB95.ttf');
  font-weight: 800;
}

html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  margin: 0;
  font-family: 'VTB Group', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #002882;

  &,
  * {
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #002882;
      border-radius: 20px;
      border: 2px solid #00c8ff;
    }

    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }
  }
}

#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
