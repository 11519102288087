.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);
  padding: calc(32px * var(--sizeCoefficient));
  box-sizing: border-box;
  z-index: 1000;
  animation: backdropAppear 0.2s forwards;
  will-change: opacity;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: calc(100% - (64px * var(--sizeCoefficient)));
  max-height: calc(100% - (64px * var(--sizeCoefficient)));
  box-sizing: border-box;
  z-index: 1001;
  animation: contentAppear 0.2s both;
  will-change: transform;
}

@keyframes backdropAppear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes contentAppear {
  0% {
    transform: translate(-50%, -50%) scale(0.8);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
