.wrapper {
  position: relative;
  z-index: 0;
  background: linear-gradient(180deg, #002882 -13.33%, #00c8ff 100%);
  padding: calc(26px * var(--sizeCoefficient)) calc(30px * var(--sizeCoefficient));
  box-sizing: border-box;
  overflow: hidden;
}

.logo {
  position: absolute;
  top: calc(34px * var(--sizeCoefficient));
  right: calc(30px * var(--sizeCoefficient));
  width: calc(145px * var(--sizeCoefficient));
  height: calc(51px * var(--sizeCoefficient));
  z-index: -1;
}
