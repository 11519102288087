.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 12%;

  &.default,
  &.promotion,
  &.chance {
    border: 1px solid #75d2fb;
  }

  &.default,
  &.promotion {
    background: #ffffff;
  }

  &.chance {
    background: #8fddff;

    .logo {
      padding: 5% 0;
    }
  }

  &.promotion {
    .logo {
      padding: 15% 0;
    }
  }

  &.backdrop {
    background: rgba(130, 217, 255, 0.9);
    filter: blur(12px);
  }
}

.logo {
  max-width: 100%;
  height: 100%;
  box-sizing: border-box;
}
