.wrapper {
  display: flex;
  flex-direction: column;
  border-radius: calc(30px * var(--sizeCoefficient));
  width: calc(806px * var(--sizeCoefficient));
  overflow: hidden;
}

.header {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #002882;
  padding: calc(22px * var(--sizeCoefficient)) calc(100px * var(--sizeCoefficient));
}

.name {
  width: calc(193px * var(--sizeCoefficient));
  height: calc(48px * var(--sizeCoefficient));
}

.content {
  flex-grow: 1;
  padding: calc(30px * var(--sizeCoefficient)) calc(50px * var(--sizeCoefficient)) calc(50px * var(--sizeCoefficient));
  background: #ffffff;
  overflow: auto;
}
