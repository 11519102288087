.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 0;
}

.gameSelector,
.game {
  flex-grow: 1;
  will-change: opacity;
}

.gameSelector {
  transition: opacity 0.2s, visibility 0s 0.2s;
}

.game {
  transition: opacity 0.4s 0.2s;
}

.hidden {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  z-index: -1;
}
