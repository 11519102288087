.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: calc(150px * var(--sizeCoefficient)) calc(48px * var(--sizeCoefficient));
  background: #002882;
  border-radius: calc(30px * var(--sizeCoefficient));
  width: calc(1000px * var(--sizeCoefficient));
}

.text {
  font-weight: 700;
  font-size: calc(50px * var(--sizeCoefficient));
  line-height: calc(64px * var(--sizeCoefficient));
  text-align: center;
  color: #ffffff;
}

.smallStar {
  width: calc(286px * var(--sizeCoefficient));
  height: calc(286px * var(--sizeCoefficient));
  position: absolute;
  right: calc(-130px * var(--sizeCoefficient));
  top: calc(30px * var(--sizeCoefficient));
  z-index: -1;
}

.mediumStar {
  position: absolute;
  width: calc(365px * var(--sizeCoefficient));
  height: calc(365px * var(--sizeCoefficient));
  right: calc(-144px * var(--sizeCoefficient));
  top: calc(198px * var(--sizeCoefficient));
  z-index: -1;
}

.superStar {
  position: absolute;
  width: calc(499px * var(--sizeCoefficient));
  height: calc(499px * var(--sizeCoefficient));
  top: calc(-88px * var(--sizeCoefficient));
  left: calc(-176px * var(--sizeCoefficient));
  z-index: -1;
}
