.wrapper {
  display: flex;
}

.characters {
  flex-shrink: 0;
}

.main {
  flex-grow: 1;
  margin-left: calc(85px * var(--sizeCoefficient));
}

.actions {
  flex-shrink: 0;
  margin-left: calc(85px * var(--sizeCoefficient));
}