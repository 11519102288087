.completeButton {
  outline: none;
  border: none;
  padding: calc(10px * var(--sizeCoefficient)) calc(25px * var(--sizeCoefficient));
  background: #002882;
  border-radius: calc(64px * var(--sizeCoefficient));
  font-weight: 400;
  font-size: calc(16px * var(--sizeCoefficient));
  line-height: calc(20px * var(--sizeCoefficient));
  text-align: center;
  color: #ffffff;
  cursor: pointer;

  &.gameCompleted {
    opacity: 0;
    pointer-events: none;
    cursor: default;
  }
}
