.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: calc(90px * var(--sizeCoefficient)) calc(118px * var(--sizeCoefficient));
  background: #002882;
  border-radius: calc(30px * var(--sizeCoefficient));
  width: calc(1138px * var(--sizeCoefficient));
}

.title {
  font-weight: 700;
  font-size: calc(50px * var(--sizeCoefficient));
  line-height: calc(64px * var(--sizeCoefficient));
  text-align: center;
  color: #FFFFFF;
}

.text {
  margin-top: calc(60px * var(--sizeCoefficient));
  font-weight: 600;
  font-size: calc(50px * var(--sizeCoefficient));
  line-height: calc(64px * var(--sizeCoefficient));
  text-align: center;
  color: #FFFFFF;
}

.star {
  position: absolute;
  width: calc(384px * var(--sizeCoefficient));
  height: calc(384px * var(--sizeCoefficient));
  right: calc(-154px * var(--sizeCoefficient));
  top: calc(-188px * var(--sizeCoefficient));
  z-index: -1;
}

.line {
  position: absolute;
  width: calc(285px * var(--sizeCoefficient));
  height: calc(322px * var(--sizeCoefficient));
  right: calc(-150px * var(--sizeCoefficient));
  top: calc(28px * var(--sizeCoefficient));
  z-index: -1;
}

.cup {
  position: absolute;
  width: calc(420px * var(--sizeCoefficient));
  height: calc(532px * var(--sizeCoefficient));
  top: calc(-62px * var(--sizeCoefficient));
  left: calc(-200px * var(--sizeCoefficient));
  z-index: -1;
}
