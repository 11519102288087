.wrapper {
  outline: none;
  padding: calc(20px * var(--sizeCoefficient)) calc(60px * var(--sizeCoefficient));
  border: 2px solid #002882;
  border-radius: calc(15px * var(--sizeCoefficient));
  background: transparent;
  font-weight: 400;
  font-size: calc(32px * var(--sizeCoefficient));
  line-height: calc(42px * var(--sizeCoefficient));
  text-align: center;
  letter-spacing: 0.05em;
  color: #002882;
  box-sizing: border-box;

  &:not(.isTicking) {
    cursor: pointer;
  }
}
