.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.panelWrapper {
  position: relative;
  z-index: 0;
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: calc(60px * var(--sizeCoefficient)) calc(170px * var(--sizeCoefficient));
  background: #FFFFFF;
  border-radius: calc(30px * var(--sizeCoefficient));
  z-index: 1;
}

.title {
  font-weight: 700;
  font-size: calc(41px * var(--sizeCoefficient));
  line-height: calc(52px * var(--sizeCoefficient));
  text-align: center;
  color: #002882;
}

.departments {
  display: flex;
  flex-direction: column;
  margin-top: calc(38px * var(--sizeCoefficient));
}

.department {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(18px * var(--sizeCoefficient)) calc(126px * var(--sizeCoefficient));
  background: #002882;
  border-radius: calc(26px * var(--sizeCoefficient));
  font-weight: 600;
  font-size: calc(41px * var(--sizeCoefficient));
  line-height: calc(52px * var(--sizeCoefficient));
  color: #FFFFFF;
  text-align: center;
  cursor: pointer;

  & + & {
    margin-top: calc(38px * var(--sizeCoefficient));
  }
}

.target {
  position: absolute;
  z-index: -1;
  width: calc(656px * var(--sizeCoefficient));
  height: calc(659px * var(--sizeCoefficient));
  left: calc(-434px * var(--sizeCoefficient));
  top: calc(-322px * var(--sizeCoefficient));
  pointer-events: none;
}

.headphones {
  position: absolute;
  width: calc(486px * var(--sizeCoefficient));
  height: calc(498px * var(--sizeCoefficient));
  top: calc(-306px * var(--sizeCoefficient));
  right: calc(-230px * var(--sizeCoefficient));
  z-index: -1;
  pointer-events: none;
}

.star {
  position: absolute;
  width: calc(394px * var(--sizeCoefficient));
  height: calc(395px * var(--sizeCoefficient));
  top: calc(210px * var(--sizeCoefficient));
  left: calc(-172px * var(--sizeCoefficient));
  z-index: 2;
  pointer-events: none;
}

.lightning {
  position: absolute;
  width: calc(441px * var(--sizeCoefficient));
  height: calc(569px * var(--sizeCoefficient));
  top: calc(170px * var(--sizeCoefficient));
  right: calc(-270px * var(--sizeCoefficient));
  z-index: 2;
  pointer-events: none;
}