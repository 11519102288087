.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: calc(40px * var(--sizeCoefficient)) calc(50px * var(--sizeCoefficient));
  border-radius: calc(30px * var(--sizeCoefficient));
  width: calc(1038px * var(--sizeCoefficient));
  min-height: calc(630px * var(--sizeCoefficient));
  background-color: #002882;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: auto;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  background: #002982;
  border-radius: calc(38px * var(--sizeCoefficient));
  padding: calc(40px * var(--sizeCoefficient)) calc(64px * var(--sizeCoefficient)) calc(48px * var(--sizeCoefficient));
  box-sizing: border-box;
}

.name {
  flex-shrink: 0;
  width: calc(322px * var(--sizeCoefficient));
  height: calc(84px * var(--sizeCoefficient));
}

.title {
  flex-shrink: 0;
  margin-top: calc(25px * var(--sizeCoefficient));
  font-weight: 400;
  font-size: calc(25px * var(--sizeCoefficient));
  line-height: calc(32px * var(--sizeCoefficient));
  letter-spacing: 0.05em;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
}

.description {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: calc(30px * var(--sizeCoefficient));
  font-weight: 600;
  font-size: calc(32px * var(--sizeCoefficient));
  line-height: calc(41px * var(--sizeCoefficient));
  text-align: center;
  white-space: pre-wrap;
  color: #ffffff;
}
