.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.titleWrapper {
  display: flex;
  justify-content: center;
}

.title {
  padding: calc(15px * var(--sizeCoefficient)) calc(40px * var(--sizeCoefficient));
  background: #FFFFFF;
  box-shadow: 0 1px 20px #82D9FF;
  border-radius: calc(64px * var(--sizeCoefficient));
  font-weight: 600;
  font-size: calc(25px * var(--sizeCoefficient));
  line-height: calc(30px * var(--sizeCoefficient));
  text-align: center;
  text-transform: uppercase;
  color: #8720FE;
}

.board {
  flex-grow: 1;
  margin-top: calc(44px * var(--sizeCoefficient));
}
